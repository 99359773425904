/* eslint-disable @next/next/no-img-element */
import React, { type ComponentProps, type FunctionComponent } from 'react';
import classNames from 'classnames';
import { Flex } from 'bb/ui';
import { type FlexProps } from 'bb/ui/Flex';
import css from './ccImages.module.scss';

export type CardType = 'visa' | 'visaElectron' | 'mastercard' | 'dankort';

export type CCImagesItemProps = ComponentProps<'img'> &
    Partial<Record<CardType, { className?: string }>>;

export const getCCImages = (
    cards: CardType[],
    props: Partial<CCImagesItemProps> = {}
) => {
    const images: React.ReactNode[] = [];
    const {
        visa: { className: visaClassName } = {},
        visaElectron: { className: visaElectronClassName } = {},
        mastercard: { className: mastercardClassName } = {},
        dankort: { className: dankortClassName } = {},
        className,
        ...restProps
    } = props;

    if (cards.includes('visa')) {
        images.push(
            <img
                {...restProps}
                key="visa"
                alt="Visa card logo"
                className={classNames(css.visa, className, visaClassName)}
                src="/images/cc/visa.png"
                loading="lazy"
            />
        );
    }
    if (cards.includes('visaElectron')) {
        images.push(
            <img
                {...restProps}
                key="visaElectron"
                alt="Visa Electron card logo"
                className={classNames(
                    css.visaElectron,
                    className,
                    visaElectronClassName
                )}
                src="/images/cc/visa-electron.png"
                loading="lazy"
            />
        );
    }
    if (cards.includes('mastercard')) {
        images.push(
            <img
                {...restProps}
                key="mastercard"
                alt="mastercard logo"
                className={classNames(
                    css.mastercard,
                    className,
                    mastercardClassName
                )}
                src="/images/cc/mastercard.png"
                loading="lazy"
            />
        );
    }
    if (cards.includes('dankort')) {
        images.push(
            <img
                {...restProps}
                key="dankort"
                alt="danKort logo"
                className={classNames(css.dankort, className, dankortClassName)}
                src="/images/cc/dankort.png"
                loading="lazy"
            />
        );
    }

    return images;
};

export type CCImagesProps = Omit<FlexProps, 'gap'>;

export const CCImages: FunctionComponent<CCImagesProps> = ({
    justifyContent = 'flexStart',
    alignItems = 'center',
    children,
    ...restProps
}) => {
    return (
        <Flex
            justifyContent={justifyContent}
            alignItems={alignItems}
            gap="small"
            {...restProps}
        >
            {children}
        </Flex>
    );
};
