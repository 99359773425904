import React, { useRef, useState, type FC } from 'react';
import { Footer } from 'bb/app/nav/Footer/Footer';
import { Navbar } from 'bb/app/nav/Navbar';
import { useRouter } from 'bb/app/router';
import { SkipButton } from 'bb/common/components/buttons/SkipButton';
import { useTranslation } from 'bb/i18n';
import { type PageLayoutProps } from 'bb/page/types';
import { type Color } from 'bb/style/types';
import { makeCSSVariableFromColor } from 'bb/style/utils';
import { Flex } from 'bb/ui';
import { getNextFocusableElement } from 'bb/utils';
import css from './layoutWithMenu.module.scss';

type LayoutWithMenuProps = PageLayoutProps & {
    bgColor?: Color;
    children?: React.ReactNode;
};

export const LayoutWithMenu: FC<LayoutWithMenuProps> = ({
    bgColor = 'tertiary-purple',
    children
}) => {
    const { t } = useTranslation(['common']);
    const { routes, navigate, router } = useRouter();

    const navbarRef = useRef<HTMLElement>(null);

    const [skipButtonFocused, setSkipButtonFocused] = useState(false);
    const [skipToSearchVisible, setSkipToSearchVisible] = useState(false);

    const isSearchPage = router.pathname === routes.search.href;

    return (
        <>
            <Flex
                className={css.skipBtn}
                alignItems="center"
                justifyContent="center"
                onBlur={() => {
                    setSkipButtonFocused(false);
                }}
                onFocus={() => {
                    setSkipButtonFocused(true);
                    setSkipToSearchVisible(true);
                }}
            >
                <SkipButton
                    color="primary-white"
                    onClick={() => {
                        if (!navbarRef.current) return;
                        getNextFocusableElement(navbarRef.current)?.focus();
                        setSkipButtonFocused(false);
                        setSkipToSearchVisible(false);
                    }}
                >
                    {t('common:accessibility_skipToContent')}
                </SkipButton>
                {skipToSearchVisible && !isSearchPage && (
                    <SkipButton
                        color="primary-white"
                        onClick={() => {
                            navigate(routes.search, { skipToSearch: 'true' });
                        }}
                    >
                        {t('common:accessibility_skipToSearch')}
                    </SkipButton>
                )}
            </Flex>
            <div
                className={css.base}
                style={makeCSSVariableFromColor(
                    '--layout-with-menu-background-color',
                    bgColor
                )}
            >
                <Navbar
                    ref={navbarRef}
                    className={skipButtonFocused ? css.shifted : ''}
                />
                {children}
                <Footer />
            </div>
        </>
    );
};
